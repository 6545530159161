<template>
	<div class="container">
		<top-header></top-header>
		<div class="designinfo">
			<div class="width-1300">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item to="/design">产品智能辅助设计</el-breadcrumb-item>
					<el-breadcrumb-item>磨机衬板辅助设计模块</el-breadcrumb-item>
				</el-breadcrumb>
				<div class="top">
					<div class="title">磨机衬板辅助设计模块</div>
					<el-button type="primary" @click="empty">清空数据</el-button>
				</div>
				<div class="jihe">
					<div class="item">
						<div class="title" :class="id == 0 ? 'title-active' : ''">
							<span>客户信息</span>
							<div @click="qh(0)">
								<i class="iconfont iconshangjiantou"></i>
							</div>
						</div>
						<div class="from">
							<div class="from-item bi">
								<label>
									<!-- <img src="../../../static/images/icon/01.png" alt=""> -->
									<span>客户名称</span>
								</label>
								<input type="text" placeholder="请输入客户名称" v-model="formData.username">
							</div>
							<div class="from-item bi">
								<label>
									<!-- <img src="../../../static/images/icon/02.png" alt=""> -->
									<span>矿山名称</span>
								</label>
								<input type="text" placeholder="请输入矿山名称" v-model="formData.mine_name">
							</div>
							<div class="from-item bi">
								<label>
									<!-- <img src="../../../static/images/icon/04.png" alt=""> -->
									<span>项目编号</span>
								</label>
								<input type="text" placeholder="请输入项目编号" v-model="formData.project_number">
							</div>
						</div>
						<div class="from mode-from" :style="{height:infoHeight + 'px'}" ref="mjinfo">
							<div class="from-item">
								<input type="text" placeholder="请输入客户名称(必填)" v-model="formData.username">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入矿山名称(必填)" v-model="formData.mine_name">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入项目编号(必填)" v-model="formData.project_number">
							</div>
						</div>
					</div>
					<!-- 矿物特性 -->
					<div class="item">
						<div class="title" :class="id == 1 ? 'title-active' : ''">
							<span>矿物特性</span>
							<div @click="qh(1)">
								<i class="iconfont iconshangjiantou"></i>
							</div>
						</div>
						<div class="from">
							<div class="from-item bi">
								<label>
									<span>邦德功指数(kWh/t)</span>
								</label>
								<input type="text" placeholder="请输入邦德功指数" v-model="formData.bond">
							</div>
							<div class="from-item bi">
								<label>
									<span>给料F80(mm)</span>
								</label>
								<input type="text" placeholder="请输入给料F80" v-model="formData.feedingba">
							</div>
							<div class="from-item">
								<label>
									<span>给料F50（mm）</span>
								</label>
								<input type="text" placeholder="请输入给料F50（mm）" v-model="formData.feedingwu">
							</div>
							<!-- from-select -->
							<div class="from-item">
								<label>
									<span>JK Axb</span>
								</label>
								<input type="text" placeholder="请输入JK  Axb" v-model="formData.jk_axb">
								<!-- <el-select v-model="value" placeholder="请选择">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select> -->
							</div>
							<div class="from-item">
								<label>
									<span>磨损指数-Abrasion Index</span>
								</label>
								<input type="text" placeholder="请输入磨损指数" v-model="formData.abrasion">
							</div>
							<div class="from-item">
								<label>
									<span>DWi(kWh/t)</span>
								</label>
								<input type="text" placeholder="请输入DW" v-model="formData.dw">
							</div>
							<div class="from-item bi">
								<label>
									<span>矿物种类</span>
								</label>
								<input type="text" placeholder="请输入矿物种类" v-model="formData.mineral_type">
							</div>
						</div>
						<div class="from mode-from" :style="{height:txHeight + 'px'}" ref="tx">
							<div class="from-item">
								<input type="text" placeholder="请输入邦德功指数(kWh/t)(必填)" v-model="formData.bond">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入给料F80(mm)(必填)" v-model="formData.feedingba">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入给料F50（mm）" v-model="formData.feedingwu">
							</div>
							<!-- from-select -->
							<div class="from-item">
								<input type="text" placeholder="请输入JK  Axb" v-model="formData.jk_axb">
								<!-- <el-select v-model="value" placeholder="请选择">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select> -->
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入磨损指数-Abrasion Index" v-model="formData.abrasion">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入DWi(kWh/t)" v-model="formData.dw">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入矿物种类(必填)" v-model="formData.mineral_type">
							</div>
						</div>
					</div>
					<!-- 磨机参数 -->
					<div class="item">
						<div class="title" :class="id == 2 ? 'title-active' : ''">
							<span>磨机参数</span>
							<div @click="qh(2)">
								<i class="iconfont iconshangjiantou"></i>
							</div>
						</div>
						<div class="from">
							<div class="from-select bi">
								<label>
									<span>磨机直径(FT/英尺)</span>
								</label>
								<el-select v-model="formData.diameter" clearable placeholder="请选择磨机直径"
									@change="diameterChange">
									<el-option v-for="item in diameterList" :key="item.id" :label="item.value"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="from-item bi">
								<label>
									<span>典型转速(rpm) [72% - 80%临界转速]</span>
								</label>
								<input type="text" placeholder="请输入典型转速 rpm" v-model="formData.speed"
									onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))">
							</div>
							<div class="from-item bi">
								<label>
									<span>预计产量(tph)</span>
								</label>
								<input type="text" placeholder="请输入预计产量" v-model="formData.yield">
							</div>
							<div class="from-item bi">
								<label>
									<span>出料 F80(mm)</span>
								</label>
								<input type="text" placeholder="请输入出料 F80(mm)" v-model="formData.dischargeba">
							</div>
							<div class="from-item bi">
								<label>
									<span>总充塡率(%) [20% - 30%总充填率]</span>
								</label>
								<input type="text" placeholder="请输入总充填率" v-model="formData.total_filling"
									onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))">
							</div>
							<div class="from-item bi">
								<label>
									<span>介质充塡率(%)</span>
								</label>
								<input type="text" placeholder="请输入介质充填率" v-model="formData.mediu_filling">
							</div>
							<div class="from-select bi">
								<label>
									<span>更换周期(days/天)</span>
								</label>
								<el-select v-model="formData.cycle" placeholder="请选择更换周期" @change="cycleChange">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="from-item bi">
								<label>
									<span>介质直径（mm）</span>
								</label>
								<input type="text" placeholder="请输入介质直径（mm）" v-model="formData.medium_diameter">
							</div>
							<div class="from-item bi" v-if="formData.diameter">
								<label>
									<span>等分数</span>
								</label>
								<input type="text" placeholder="请输入等分数" v-model="formData.score" disabled>
							</div>
							<div class="from-item">
								<label>
									<span>功率(kW)</span>
								</label>
								<input type="text" placeholder="请输入功率" v-model="formData.power">
							</div>
						</div>
						<div class="from mode-from" :style="{height:dataHeight + 'px'}" ref="data">
							<div class="from-select">
								<el-select v-model="formData.diameter" clearable placeholder="请选择磨机直径(FT/英尺)(必选)"
									@change="diameterChange">
									<el-option v-for="item in diameterList" :key="item.id" :label="item.value"
										:value="item.id">
									</el-option>
								</el-select>
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入典型转速 rpm[72% - 80%临界转速](必填)" v-model="formData.speed"
									onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入预计产量(tph)(必填)" v-model="formData.yield">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入出料 F80(mm)(必填)" v-model="formData.dischargeba">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入总充塡率(%)[20% - 30%总充填率](必填)"
									v-model="formData.total_filling"
									onKeypress="return(/[\d.]/.test(String.fromCharCode(event.keyCode)))">
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入介质充填率(%)(必填)" v-model="formData.mediu_filling">
							</div>
							<div class="from-select">
								<el-select v-model="formData.cycle" placeholder="请选择更换周期(days/天)(必选)">
									<el-option v-for="item in options" :key="item.value" :label="item.label"
										:value="item.value">
									</el-option>
								</el-select>
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入介质直径（mm）(必填)">
							</div>
							<div class="from-item" v-if="formData.diameter">
								<input type="text" v-model="formData.score" placeholder="请输入等分数" disabled>
							</div>
							<div class="from-item">
								<input type="text" placeholder="请输入功率(kW)" v-model="formData.power">
							</div>
						</div>
					</div>
				</div>
				<div class="submit">
					<div class="from">
						<div class="from-submit" @click="calculation">
							<button>计算衬板尺寸</button>
						</div>
						<div class="export-tab" v-if="resData.brightness">
							<el-button :loading="downloadLoading" type="primary" icon="el-icon-download"
								@click="demoDc">导出</el-button>
						</div>
					</div>
				</div>
				<div class="result" v-if="resData.brightness">
					<div class="title">
						<span>计算获得的数据</span>
					</div>
					<div class="result-item">
						<div class="item">
							<span>提升条高度 - mm：</span>
							<span>{{resData.brightness}}</span>
						</div>
						<div class="item">
							<span>基板厚度 - mm：</span>
							<span>{{resData.thickness}}</span>
						</div>
						<div class="item">
							<span>提升条角度 - 度：</span>
							<span>{{resData.angle}}</span>
						</div>
						<div class="item">
							<span>衬板等分数：</span>
							<span>{{resData.score}}</span>
						</div>
					</div>
				</div>
				<div class="model" v-if="resData.brightness">
					<div class="title">
						<span>衬板三维模型</span>
					</div>
					<div class="model-item">
						<iframe :src="resData.demo" frameborder="0"></iframe>
					</div>
				</div>
			</div>
		</div>
		<bottom-footer></bottom-footer>
		<info-mask v-if="isshow" @close="close"></info-mask>
	</div>
</template>

<script>
	import topHeader from '../../components/header.vue'
	import bottomFooter from '../../components/footer.vue'
	import infoMask from '../../components/infomask.vue'
	export default {
		components: {
			topHeader,
			bottomFooter,
			infoMask
		},
		data() {
			return {
				id: -1,
				isshow: false,
				infoHeight: 0,
				txHeight: 0,
				dataHeight: 0,
				downloadLoading: false,
				diameterList: [{
					id: '22',
					value: '22FT'
				}, {
					id: '24',
					value: '24FT'
				}, {
					id: '28',
					value: '28FT'
				}, {
					id: '32',
					value: '32FT'
				}, {
					id: '34',
					value: '34FT'
				}, {
					id: '36',
					value: '36FT'
				}, {
					id: '38',
					value: '38FT'
				}, {
					id: '40',
					value: '40FT'
				}],
				options: [{
					value: '180',
					label: '180天'
				}, {
					value: '150',
					label: '150天'
				}, {
					value: '120',
					label: '120天'
				}, {
					value: '90',
					label: '90天'
				}],
				value: '',
				formData: {
					username: '', // 客户名称
					mine_name: '', // 矿山名称
					project_number: '', // 项目编号
					bond: '', // 邦德功指数
					feedingba: '', // 给料F80
					feedingwu: '', // 给料F50
					jk_axb: '', //JK Axb
					abrasion: '', // 磨损指数
					dw: '', // DW
					mineral_type: '', //矿物种类
					diameter: '', //磨机直径
					speed: '', // 典型转速
					yield: '', // 预计产量
					dischargeba: '', // 出料F80
					total_filling: '', //总填充率
					mediu_filling: '', //介质填充率
					cycle: '', // 更换周期
					medium_diameter: '', //介质直径
					power: '', //功率
					score: '', // 等分数
				},
				resData: { // 计算数据
					username: '', // 客户名称
					mine_name: '', // 矿山名称
					project_number: '', // 项目编号
					bond: '', // 邦德功指数
					feedingba: '', // 给料F80
					feedingwu: '', // 给料F50
					jk_axb: '', //JK Axb
					abrasion: '', // 磨损指数
					dw: '', // DW
					mineral_type: '', //矿物种类
					diameter: '', //磨机直径
					speed: '', // 典型转速
					yield: '', // 预计产量
					dischargeba: '', // 出料F80
					total_filling: '', //总填充率
					mediu_filling: '', //介质填充率
					cycle: '', // 更换周期
					medium_diameter: '', //介质直径
					power: '', //功率
					score: '', // 等分数
					brightness: '', // 提升条亮度
					angle: '', //提升条角度
					thickness: '', //厚度
					demo: '', // 3D模型图
				}
			}
		},
		created() {

		},
		methods: {
			// 导出 计算结果
			demoDc() {
				this.downloadLoading = true;
				import('../../vendor/Export2Excel.js').then(excel => {
					const tHeader = ['客户名称','矿山名称','项目编号','邦德功指数(kWh/t)','给料F80(mm)','给料F50（mm）','JK Axb','磨损指数-Abrasion Index','DWi(kWh/t)','矿物种类','磨机直径(FT/英尺)','典型转速(rpm) [72% - 80%临界转速]','预计产量(tph)','出料 F80(mm)','总充塡率(%) [20% - 30%总充填率]','介质充塡率(%)','更换周期(days/天)','介质直径（mm）','功率(kW)','提升条高度 - mm', '基板厚度 - mm', '提升条角度 - 度','衬板等分数']; // 导出excel 的标题
					const filterVal = ['username', 'mine_name', 'project_number','bond','feedingba','feedingwu','jk_axb','abrasion','dw','mineral_type','diameter','speed','yield','dischargeba','total_filling','mediu_filling','cycle','medium_diameter','power','brightness','thickness','angle','score']; // 每个标题对应的字段
					const list = [{
						username: this.resData.username, // 客户名称
						mine_name: this.resData.mine_name, // 矿山名称
						project_number: this.resData.project_number, // 项目编号
						bond: this.resData.bond, // 邦德功指数
						feedingba: this.resData.feedingba, // 给料F80
						feedingwu: this.resData.feedingwu, // 给料F50
						jk_axb: this.resData.jk_axb, //JK Axb
						abrasion: this.resData.abrasion, // 磨损指数
						dw: this.resData.dw, // DW
						mineral_type: this.resData.mineral_type, //矿物种类
						diameter: this.resData.diameter, //磨机直径
						speed: this.resData.speed, // 典型转速
						yield: this.resData.yield, // 预计产量
						dischargeba: this.resData.dischargeba, // 出料F80
						total_filling: this.resData.total_filling, //总填充率
						mediu_filling: this.resData.mediu_filling, //介质填充率
						cycle: this.resData.cycle, // 更换周期
						medium_diameter: this.resData.medium_diameter, //介质直径
						power: this.resData.power, //功率
						brightness: this.resData.brightness, // 提升条高度
						thickness: this.resData.thickness, //厚度
						angle: this.resData.angle, //提升条角度
						score: this.resData.score, // 等分数
					}];

					if (list) {
						const data = this.formatJson(filterVal, list); // 生成json数据
						excel.export_json_to_excel({ // 调用excel方法生成表格
							header: tHeader,
							data,
							filename: '数据表格'
						});
					} else {
						alert('暂无无数据');
					}
					this.downloadLoading = false;
				})
			},
			formatJson(filterVal, jsonData) {
				return jsonData.map(v => filterVal.map(j => v[j]));
			},
			calculation() {
				if (this.formData.username == '') {
					this.$notify({
						title: '提示',
						message: '用户名不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.mine_name == '') {
					this.$notify({
						title: '提示',
						message: '矿山名称不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.project_number == '') {
					this.$notify({
						title: '提示',
						message: '项目编号不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.bond == '') {
					this.$notify({
						title: '提示',
						message: '邦德功指数不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.feedingba == '') {
					this.$notify({
						title: '提示',
						message: '给料F80不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.mineral_type == '') {
					this.$notify({
						title: '提示',
						message: '矿物种类不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.diameter == '') {
					this.$notify({
						title: '提示',
						message: '磨机直径不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.speed == '') {
					this.$notify({
						title: '提示',
						message: '典型转速不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.yield == '') {
					this.$notify({
						title: '提示',
						message: '预计产量不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.dischargeba == '') {
					this.$notify({
						title: '提示',
						message: '出料F80不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.total_filling == '') {
					this.$notify({
						title: '提示',
						message: '总填充率不可为空！',
						type: 'warning'
					});
					return false
				}
				if (20 > parseFloat(this.formData.total_filling).toFixed(3) || parseFloat(this.formData.total_filling)
					.toFixed(3) > 30) {
					this.$notify({
						title: '提示',
						message: '总充填率超出指定范围！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.mediu_filling == '') {
					this.$notify({
						title: '提示',
						message: '介质填充率不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.cycle == '') {
					this.$notify({
						title: '提示',
						message: '更换周期不可为空！',
						type: 'warning'
					});
					return false
				}
				if (this.formData.medium_diameter == '') {
					this.$notify({
						title: '提示',
						message: '介质直径不可为空！',
						type: 'warning'
					});
					return false
				}
				this.$post('liner/design', {
					bwi: this.formData.bond,
					cycle: this.formData.cycle,
					dia: this.formData.diameter,
					revolutions: parseFloat(this.formData.speed).toFixed(3),
					filling: parseFloat(this.formData.total_filling).toFixed(3)
				}).then(res => {
					if (res.data.status == 200) {
						this.resData.username = this.formData.username, // 客户名称
						this.resData.mine_name = this.formData.mine_name, // 矿山名称
						this.resData.project_number = this.formData.project_number, // 项目编号
						this.resData.bond = this.formData.bond, // 邦德功指数
						this.resData.feedingba = this.formData.feedingba, // 给料F80
						this.resData.feedingwu = this.formData.feedingwu, // 给料F50
						this.resData.jk_axb = this.formData.jk_axb, //JK Axb
						this.resData.abrasion = this.formData.abrasion, // 磨损指数
						this.resData.dw = this.formData.dw, // DW
						this.resData.mineral_type = this.formData.mineral_type, //矿物种类
						this.resData.diameter = this.formData.diameter, //磨机直径
						this.resData.speed = this.formData.speed, // 典型转速
						this.resData.yield = this.formData.yield, // 预计产量
						this.resData.dischargeba = this.formData.dischargeba, // 出料F80
						this.resData.total_filling = this.formData.total_filling, //总填充率
						this.resData.mediu_filling = this.formData.mediu_filling, //介质填充率
						this.resData.cycle = this.formData.cycle, // 更换周期
						this.resData.medium_diameter = this.formData.medium_diameter, //介质直径
						this.resData.power = this.formData.power, //功率
						this.resData.score = this.formData.score, // 等分数
						this.resData.brightness = parseInt(res.data.data[0]);
						this.resData.thickness = parseInt(res.data.data[1]);
						this.resData.angle = parseFloat(this.getBracketStr(res.data.data[2])).toFixed(1);
					} else {
						this.$notify({
							title: '提示',
							message: res.data.message,
							type: 'warning'
						});
					}
				})
			},
			getBracketStr(text) {
				let result = ''
				let regex = /\[(.+?)\]/g;
				let options = text.match(regex);
				//console.log(options);
				let option = options[0];
				result = option.substring(1, option.length - 1);
				return result
			},
			empty() {
				location.reload();
			},
			cycleChange() {
				if (this.resData.brightness != '') {
					this.$notify({
						title: '提示',
						message: '计算参数有变，请重新计算！',
						type: 'warning'
					});
					this.resData.brightness = '';
				}
			},
			diameterChange(e) {
				//console.log(e);
				if (e == 22) {
					this.formData.score = '36'
					this.resData.demo = 'http://www.naipuminingcloud.com/22FT.html'
				} else if (e == 24) {
					this.formData.score = '36'
					this.resData.demo = 'http://www.naipuminingcloud.com/24FT.html'
				} else if (e == 28) {
					this.formData.score = '40'
					this.resData.demo = 'http://www.naipuminingcloud.com/28FT.html'
				} else if (e == 32) {
					this.resData.demo = 'http://www.naipuminingcloud.com/32FT.html'
				} else if (e == 34) {
					this.formData.score = '32/64'
					this.resData.demo = 'http://www.naipuminingcloud.com/34FT.html'
				} else if (e == 36) {
					this.formData.score = '48'
					this.resData.demo = 'http://www.naipuminingcloud.com/36FT.html'
				} else if (e == 38) {
					this.formData.score = '68'
					this.resData.demo = 'http://www.naipuminingcloud.com/38FT.html'
				} else if (e == 40) {
					this.formData.score = '64'
					this.resData.demo = 'http://www.naipuminingcloud.com/40FT.html'
				}
			},
			close() {
				this.isshow = false;
			},
			qh(index) {
				// console.log(this.$refs);
				this.id = index;
				if (index == 0) {
					this.infoHeight = 61 * this.$refs.mjinfo.children.length;
					this.txHeight = 0;
					this.dataHeight = 0;
				} else if (index == 1) {
					this.txHeight = 61 * this.$refs.tx.children.length;
					this.infoHeight = 0;
					this.dataHeight = 0;
				} else {
					this.dataHeight = 61 * this.$refs.data.children.length;
					this.infoHeight = 0;
					this.txHeight = 0;
				}
			}
		}
	}
</script>

<style lang="scss">
	.model {
		margin-top: 20px;
		text-align: center;
		padding-bottom: 20px;

		.title {
			text-align: left;
			font-size: 14px;
			font-weight: 800;
		}

		iframe {
			width: 100%;
			margin-top: 40px;
			height: 600px;

			@media screen and (min-width:320px) and (max-width:767px) {
				width: 100%;
			}
		}
	}

	.result {
		margin-top: 40px;

		.title {
			// color: #3292F3;
			font-size: 14px;
			font-weight: 800;
		}

		.item {
			display: inline-block;
			width: 45%;
			font-size: 14px;
			margin-top: 20px;
			margin-right: 5%;

			@media screen and (min-width:320px) and (max-width:767px) {
				width: 100%;
			}

			span {
				vertical-align: middle;
				font-size: 14px;

				&:nth-child(2) {
					font-weight: 800;
					// color: #0064C8;
					margin-right: 5px;
				}
			}

			img {
				width: 20px;
				height: 20px;
				vertical-align: middle;
				margin-right: 10px;
			}
		}
	}

	.designinfo {
		margin-top: 60px;
		min-height: 774px;
		padding-top: 20px;

		.top {
			margin: 30px 0;

			@media screen and(min-width:320px) and (max-width:767px) {
				display: flex;
				justify-content: space-between;
			}

			.title {
				font-size: 26px;
				letter-spacing: 2px;
				display: inline-block;
				vertical-align: middle;

				@media screen and(min-width:320px) and (max-width:767px) {
					font-size: 14px;
					letter-spacing: 0;
					padding-top: 8px;
				}
			}

			.el-button {
				vertical-align: middle;
				border-color: #00337D;
				background-color: #00337D;
				padding: 8px 20px;
				border-radius: 0;
				margin-left: 40px;
			}
		}

		.submit {
			margin: 20px 0;

			.from {
				position: relative;

				.export-tab {
					position: absolute;
					right: 20%;
					top: 0;
					
					button {
						background-color: #00337D;
						border: 1px solid #00337D;
					}
					@media screen and (min-width:320px) and (max-width: 767px) {
						position: relative;
						right: 0;
						left: 0;
						margin-top: 15px;
						button{
							width: 100%;
						}
					}
				}

				&-submit {
					margin-top: 50px;
					text-align: center;

					button {
						background: #00337D;
						color: #fff;
						border: 0;
						font-size: 14px;
						border-radius: 5px;
						cursor: pointer;
						padding: 10px 150px;

						@media screen and (min-width:320px) and (max-width:767px) {
							width: 100%;
							padding: 10px 0;
						}
					}
				}
			}
		}

		.jihe {
			background-color: #fff;
			padding-bottom: 5px;

			@media screen and (min-width:320px) and (max-width:767px) {
				background-color: rgba($color: #000000, $alpha: 0);
			}

			.item {
				margin-bottom: 20px;
				background-color: #fff;

				@media screen and (min-width:320px) and (max-width:767px) {
					box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.15);
					border-radius: 5px;
					padding: 20px;
					box-sizing: border-box;
					margin-right: 0;
				}

				.title {
					border-bottom: 1px solid #e5e5e5;
					padding: 20px;
				}

				.title,
				.title-active {
					span {
						font-weight: 800;
					}

					@media screen and (min-width:320px) and (max-width:767px) {
						display: flex;
						justify-content: space-between;
						border: 0;
						padding: 0;
					}

					div {
						display: none;
						transition: all .3s;

						@media screen and (min-width:320px) and (max-width:767px) {
							display: block;
						}
					}

					span {
						padding-bottom: 3px;

						@media screen and (min-width:320px) and (max-width:767px) {
							// border-left: 4px solid #3292F3;
							border-bottom: 0;
							padding-bottom: 0;
							font-weight: 500;
							font-size: 14px;
						}
					}
				}

				.title-active {
					div {
						transform: rotate(180deg);
					}
				}

				.mode-from {
					height: 0;
					transition: height .4s;
					overflow: hidden;
					display: none;

					@media screen and (min-width:320px) and (max-width:767px) {
						display: block !important;
					}
				}

				.from {
					padding: 0 20px;

					@media screen and (min-width:320px) and (max-width:767px) {
						display: none;
						padding: 0 0;
					}

					.from-select {
						display: inline-block;
						vertical-align: middle;
						width: 33.333%;
						margin-top: 20px;

						.el-date-editor.el-input,
						.el-date-editor.el-input__inner {
							width: 85%;
						}

						label {
							display: block;
							margin-bottom: 10px;
							vertical-align: middle;
							font-size: 12px;

							span {
								width: 70%;
								vertical-align: middle;
								display: inline-block;
							}

							.iconfont {
								margin-right: 10px;
								vertical-align: middle;
							}

							img {
								width: 20px;
								height: 20px;
								vertical-align: middle;
								margin-right: 10px;
							}
						}

						.el-select {
							width: 85%;
						}

						@media screen and (min-width:320px) and (max-width:767px) {
							width: 100%;
							border-bottom: 1px solid #E2E2E2;

							label {
								font-size: 12px;
								width: 35%;
							}

							.el-select {
								width: 100%;

								.el-input__inner {
									border: 0;
									padding: 0 0;
								}
							}
						}
					}

					.bi {
						position: relative;

						label {
							padding-left: 10px;
						}

						&::after {
							content: '*';
							position: absolute;
							top: 0;
							left: 0;
							color: red;
						}
					}

					.from-item {
						display: inline-block;
						vertical-align: middle;
						width: 33.333%;
						margin-top: 20px;

						label {
							display: block;
							margin-bottom: 10px;
							vertical-align: middle;
							font-size: 12px;

							span {
								width: 70%;
								vertical-align: middle;
								display: inline-block;
							}

							.iconfont {
								margin-right: 10px;
								vertical-align: middle;
							}

							img {
								width: 20px;
								height: 20px;
								vertical-align: middle;
								margin-right: 10px;
							}
						}

						input {
							width: 85%;
							height: 40px;
							border-radius: 5px;
							border: 1px solid #ddd;
							vertical-align: middle;
							transition: border-color .3s;
							padding: 0 15px;
							box-sizing: border-box;

							&:focus {
								border-color: #409EFF;
								outline: none;
							}
						}

						@media screen and (min-width:320px) and (max-width:767px) {
							width: 100%;
							border-bottom: 1px solid #E2E2E2;

							label {
								width: 35%;
								font-size: 12px;
							}

							input {
								border: 0;
								width: 100%;
								padding: 0 0;
							}

							.el-date-editor.el-input,
							.el-date-editor.el-input__inner {
								width: 100%;
								padding: 0 35px;
							}
						}
					}

					&-submit {
						margin-top: 50px;
						text-align: center;

						button {
							background-color: #3292F3;
							color: #fff;
							border: 0;
							font-size: 14px;
							border-radius: 5px;
							cursor: pointer;
							padding: 10px 150px;

							@media screen and (min-width:320px) and (max-width:767px) {
								width: 100%;
								padding: 10px 0;
							}
						}
					}
				}
			}
		}
	}
</style>
